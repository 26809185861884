import { cancelSingleSubscription, getSubscription } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';
// ================ Action types ================ //
export const PLAN_LIST_REQUEST = 'app/MembershipPage/PLAN_LIST_REQUEST';
export const PLAN_LIST_SUCCESS = 'app/MembershipPage/PLAN_LIST_SUCCESS';
export const PLAN_LIST_ERROR = 'app/MembershipPage/PLAN_LIST_ERROR';
export const Delete_REQUEST = 'app/MembershipPage/delete_REQUEST';
export const DELETE_ERROR = 'app/MembershipPage/DELETE_ERROR';

// ================ Reducer ================ //
const initialState = {
  planListError: null,
  deleteSubscriptionError: null,
  planListInProgress: true,
  planList: [],
  deleteRequestId: ''
};
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case PLAN_LIST_REQUEST:
      return {
        ...state,
        planList: null,
        planListError: null,
        planListInProgress: true,
      };
    case PLAN_LIST_SUCCESS:
      return {
        ...state,
        planListInProgress: false,
        planList: payload
      };
    case PLAN_LIST_ERROR:
      return {
        ...state,
        planListInProgress: false,
        planListError: payload
      };
    case Delete_REQUEST:
      return {
        ...state,
        deleteRequestId: payload.subscriptionId
      }
    // case DELETE_ERROR:
    //   console.log('payload', payload)
    //   return {
    //     ...state,
    //     deleteSubscriptionError: payload.message
    //   };
    case DELETE_ERROR:
      return {
        ...state,
        deleteSubscriptionErrors: {
          ...state.deleteSubscriptionError,
          [payload.subscriptionId]: payload
        }
      };
    default:
      return state;
  }
}
// ================ Action creators ================ //
export const planListRequest = () => ({ type: PLAN_LIST_REQUEST });
export const planListSuccess = (res) => ({ type: PLAN_LIST_SUCCESS, payload: res });
export const planListError = (res) => ({ type: PLAN_LIST_ERROR, payload: res });
export const deleteSubscriptionRequest = (res) => ({ type: Delete_REQUEST, payload: res });
// export const deleteSubscriptionError = (subscriptionId, error) => ({ type: DELETE_ERROR, payload: { subscriptionId, error } });
export const deleteSubscriptionError = (error) => ({
  type: DELETE_ERROR,
  payload: error,
});
export const getSubscriptionThroughChargeBee = () => async (dispatch, getState, sdk) => {
  let { currentUser } = getState().user;
  dispatch(planListRequest()); // Dispatch a request action

  if (!currentUser || (currentUser && !currentUser.id)) {
    currentUser = await dispatch(fetchCurrentUser());
  }

  try {

    const { uuid: customerId } = (currentUser && currentUser.id) || {};

    if (customerId) {
      getSubscription({ customerId })
        .then(res => {
          dispatch(planListSuccess(res));
        });
    } else {
      dispatch(planListError("Couldn't fetch the account. Please reload Again"))
      console.error('Customer ID not found.');
    }
  } catch (error) {
    // Dispatch an error action if something goes wrong
    dispatch(planListError());
    console.error('Error fetching subscription:', error);
  }
};

export const deleteSubscriptionThroughChargeBee = ({ subscriptionId }) => async (dispatch, getState, sdk) => {
  dispatch(deleteSubscriptionRequest({ subscriptionId }));

  try {
    const res =  await cancelSingleSubscription({ subscriptionId });
    dispatch(getSubscriptionThroughChargeBee()); // Refresh the subscription list after deletion
    return res;
  } catch (error) {
    const errorDetails = {
      subscriptionId: subscriptionId,
      message: error.message || 'Failed to cancel subscription', // Adjust according to the structure of your error object
      error: error
    };
    // Dispatch an error action with the error details
    dispatch(deleteSubscriptionError(errorDetails));
    console.error('Error deleting subscription:', error);
  }
  
};

// ================ Thunks ================ //
export const loadData = () => (dispatch, getState, sdk) => dispatch(getSubscriptionThroughChargeBee());