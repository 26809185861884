import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug, LISTING_PAGE_PARAM_TYPE_DRAFT, LISTING_PAGE_PARAM_TYPE_EDIT } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage, NamedRedirect, Button } from '../../components';

import css from './ListingCard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { requestCreateListingDraft } from '../../containers/EditListingPage/EditListingPage.duck';
import { useHistory } from 'react-router-dom';
import IconCollection from '../IconCollection/IconCollection';
import { courseDressCode, courseLevels, courseRequirements, courseType } from '../../config/configListing';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';



const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};


const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          {/* <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} /> */}
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const history = useHistory();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isTemplate
  } = props;


  const [openDetails, setOpenDetails] = useState("")
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, state } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const isDraft = state === LISTING_STATE_DRAFT;

  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;



  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const draftListingDeatils = {
    title: listing?.attributes?.title,
    price: listing?.attributes?.price,
    description: listing?.attributes?.description,
    // images:listing?.images,
    publicData: listing?.attributes?.publicData
  }

  const { courseListingType, ...updatedPublicDataWithoutTemplate } = draftListingDeatils.publicData;
  const updatedPublicData = {
    ...updatedPublicDataWithoutTemplate,
    courseListingType: "variant",
    templateListingId:listing?.id.uuid
  };

  const updatedDraftListingDetails = {
    ...draftListingDeatils,
    publicData: updatedPublicData
  };

  const createNewListing = async () => {
    const res = await dispatch(requestCreateListingDraft(updatedDraftListingDetails, config));
    const { data } = res?.data;
    const slug = createSlug(data?.attributes?.title);
    typeof window != "undefined" && localStorage.setItem('isTemplate', 'variant')
    return history.push(`/l/${slug}/${data?.id?.uuid}/draft/location`)
  }
  
  const { currentUser } = useSelector(state => state.user);

  const courseLevelsKey = publicData?.courseLevels;
  const courseForDesc = publicData?.courseForDescription;
  const courseRequirementsKey = publicData?.courseRequirements;
  const courseTypeKey = publicData?.courseType;
  const listItemsLabels = publicData?.listItems;
  const courseLevelsLabels = courseLevels.filter(item => item.key === courseLevelsKey).map(item => item.label);
  const courseRequirementsLabels = courseRequirements.filter(item => item.key === courseRequirementsKey).map(item => item.label);
  const courseTypeLabels = courseType.filter(item => item.key === courseTypeKey).map(item => item.label);

  const { bagItems = [] } = !!currentUser?.id && currentUser.attributes.profile.protectedData || {};        
  const index = bagItems && bagItems.findIndex(e => e.id == id);

  const handleBagItems = async() => {
    if (!currentUser?.id) {
      <NamedRedirect name="LoginPage"/>
    } else {
      if (index > -1) {
        bagItems.splice(index, 1);
      } else {
        bagItems.push({ id, quantity: 1 });
      }
      const profile = {
        protectedData: {
          bagItems
        }
      }
      await dispatch(updateProfile(profile));
   
    }
  };

  

  return (
    <>
      {courseListingType === "product" ?
        <div className={classes}>
          <NamedLink name="ListingPage" params={{ id, slug }}>
            <AspectRatioWrapper
              className={css.productAspectRatioWrapper}
              width={aspectWidth}
              height={aspectHeight}
              {...setActivePropsMaybe}
            >
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={variants}
                sizes={renderSizes}
              />
            </AspectRatioWrapper>
        
          <div className={css.productInfo}>
            <div className={css.productTitle}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            <div className={css.bottomBox}>
              <div className={css.priceBox}>
                <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
                <span className={css.eachText}>| Each</span>
              </div>
              <div>
              {index > -1 ? <span className={css.addCart} onClick={(e) => {
                  e.preventDefault();
                  handleBagItems();
                }}>- Remove To Cart
                </span>
                  :
                  <span className={css.addCart} onClick={(e) => {
                    e.preventDefault();
                    handleBagItems();
                  }}> + Add To Cart</span>}
              </div>
              {/* <button className={css.addCart}>+ Add To Cart</button> */}
            </div>
          </div>
          </NamedLink>
        </div>
        :
        <div className={classes}>
          <NamedLink name="ListingPage" params={{ id, slug }}>
            <AspectRatioWrapper
              className={css.aspectRatioWrapper}
              width={aspectWidth}
              height={aspectHeight}
              {...setActivePropsMaybe}
            >
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={variants}
                sizes={renderSizes}
              />
            </AspectRatioWrapper>
            <div className={css.info}>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
            </div>


          </NamedLink>
          {!isTemplate && <div className={css.cardInfo}>
            <div>
              <h5 className={css.label}>
                <IconCollection name="iconMans" />
                Who This Is For:</h5>
                {
                  openDetails ?  <p className={css.closedetails}>{courseForDesc}</p>:  <p className={css.details}>{courseForDesc}</p>
                }
            
              {openDetails == id && <div>
                <h5 className={css.label}>
                  <IconCollection name="iconBag" />
                  <span>What’s Included</span>
                </h5>
                {listItemsLabels && listItemsLabels.length && listItemsLabels.map((item, index) => {
                  return (
                    <ul key={index}>
                      <li>
                        {item}
                      </li>
                    </ul>
                  )
                })}
                <h5 className={css.label}>
                  <IconCollection name="iconCourse" />
                  <span> Course List</span>
                </h5>
                <ul className={css.listWrapper}>
                  <li>
                    {courseLevelsLabels}
                  </li>
                </ul>
              </div>}
              {openDetails ? <div>
                <NamedLink className={css.bookBtn} name="ListingPage" params={{ id, slug }}>Select And Book Class</NamedLink>
                <button className={css.closeBtn} onClick={() => setOpenDetails(null)}>close</button>
              </div> : <Button className={css.openDetailsBtn} onClick={() => setOpenDetails(id)}>Open Details</Button>}
            </div>
          </div>}
          <div className={css.buttonWrapper}>   {isTemplate &&
            <div className={css.saveButton} onClick={() => createNewListing()}>
              Save & continue
            </div>
          }</div>
        </div>}
    </>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
