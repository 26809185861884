import React from 'react';
import classNames from 'classnames';

const ICON_LOCK = 'iconLock';
const ICON_EMAIL = "iconEmail";
const ICON_PHONE = "iconPhone";
const ICON_MANS = "iconMans";
const ICON_BAG = "iconBag";
const ICON_COURSE = "iconCourse";

const IconCollection = props => {
    const { className, rootClassName, name } = props;


    switch (name) {
        case ICON_LOCK:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_216_3695)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6 8C6 6.4087 6.63214 4.88258 7.75736 3.75736C8.88258 2.63214 10.4087 2 12 2C13.5913 2 15.1174 2.63214 16.2426 3.75736C17.3679 4.88258 18 6.4087 18 8H19C19.5304 8 20.0391 8.21071 20.4142 8.58579C20.7893 8.96086 21 9.46957 21 10V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V10C3 9.46957 3.21071 8.96086 3.58579 8.58579C3.96086 8.21071 4.46957 8 5 8H6ZM12 4C13.0609 4 14.0783 4.42143 14.8284 5.17157C15.5786 5.92172 16 6.93913 16 8H8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4ZM14 14C14 14.3511 13.9076 14.6959 13.732 15C13.5565 15.304 13.304 15.5565 13 15.732V17C13 17.2652 12.8946 17.5196 12.7071 17.7071C12.5196 17.8946 12.2652 18 12 18C11.7348 18 11.4804 17.8946 11.2929 17.7071C11.1054 17.5196 11 17.2652 11 17V15.732C10.6187 15.5119 10.3208 15.1721 10.1523 14.7653C9.98384 14.3586 9.95429 13.9076 10.0682 13.4824C10.1822 13.0571 10.4333 12.6813 10.7825 12.4133C11.1318 12.1453 11.5597 12 12 12C12.5304 12 13.0391 12.2107 13.4142 12.5858C13.7893 12.9609 14 13.4696 14 14Z" fill="#F7F9FB" />
                    </g>
                    <defs>
                        <clipPath id="clip0_216_3695">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            );
        case ICON_EMAIL:
            return (
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_35_1765)">
                        <path d="M24.889 4.6665H3.11122C2.69866 4.6665 2.303 4.83039 2.01128 5.12212C1.71955 5.41384 1.55566 5.8095 1.55566 6.22206V21.7776C1.55566 22.1902 1.71955 22.5858 2.01128 22.8776C2.303 23.1693 2.69866 23.3332 3.11122 23.3332H24.889C25.3016 23.3332 25.6972 23.1693 25.9889 22.8776C26.2807 22.5858 26.4446 22.1902 26.4446 21.7776V6.22206C26.4446 5.8095 26.2807 5.41384 25.9889 5.12212C25.6972 4.83039 25.3016 4.6665 24.889 4.6665ZM23.6912 21.7776H4.40233L9.84678 16.1465L8.72678 15.0654L3.11122 20.8754V7.40428L12.779 17.0254C13.0705 17.3151 13.4647 17.4777 13.8757 17.4777C14.2866 17.4777 14.6809 17.3151 14.9723 17.0254L24.889 7.16317V20.7743L19.1646 15.0498L18.0679 16.1465L23.6912 21.7776ZM4.13011 6.22206H23.629L13.8757 15.9209L4.13011 6.22206Z" fill="#FC0658" />
                    </g>
                    <defs>
                        <clipPath id="clip0_35_1765">
                            <rect width="28" height="28" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case ICON_PHONE:
            return (
                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.4239 19.4663L20.4539 17.4363C20.7273 17.1663 21.0733 16.9814 21.4497 16.9042C21.8261 16.827 22.2169 16.8607 22.5745 17.0013L25.0486 17.9891C25.41 18.1358 25.7199 18.3862 25.9393 18.7088C26.1586 19.0313 26.2775 19.4116 26.2811 19.8016V24.3328C26.279 24.5982 26.2232 24.8603 26.1171 25.1035C26.011 25.3468 25.8568 25.566 25.6638 25.748C25.4707 25.9301 25.2429 26.0711 24.9938 26.1628C24.7448 26.2544 24.4799 26.2947 24.2149 26.2813C6.8783 25.2028 3.38017 10.5216 2.71861 4.90285C2.6879 4.62693 2.71596 4.34763 2.80094 4.08334C2.88593 3.81904 3.0259 3.57573 3.21167 3.36942C3.39744 3.1631 3.62479 2.99846 3.87875 2.88632C4.13272 2.77418 4.40756 2.71708 4.68517 2.71879H9.06236C9.45296 2.71994 9.83429 2.83793 10.1573 3.05757C10.4803 3.27721 10.7302 3.58846 10.8749 3.95129L11.8627 6.42535C12.0079 6.78157 12.045 7.17268 11.9692 7.54984C11.8934 7.92699 11.7082 8.27346 11.4367 8.54597L9.40674 10.576C9.40674 10.576 10.5758 18.4875 18.4239 19.4663Z" fill="#FC0658" />
                </svg>
            );
        case ICON_MANS:
            return (
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.9041 11.7255C17.7182 11.7255 19.2789 10.1057 19.2789 7.99625C19.2789 5.91228 17.71 4.36841 15.9041 4.36841C14.0986 4.36841 12.5293 5.94596 12.5293 8.01309C12.5293 10.1057 14.09 11.7255 15.9041 11.7255ZM6.19275 11.9198C7.76209 11.9198 9.12936 10.502 9.12936 8.67105C9.12936 6.85734 7.75346 5.51553 6.19275 5.51553C4.62382 5.51553 3.23971 6.89102 3.24834 8.6883C3.24834 10.502 4.6152 11.9198 6.19316 11.9198M1.48514 19.5385H7.89721C7.01993 18.2645 8.09148 15.6996 9.90561 14.2991C8.96918 13.6748 7.76209 13.2107 6.18454 13.2107C2.37927 13.2103 0 16.0195 0 18.3569C0 19.1167 0.421804 19.5385 1.48514 19.5385ZM10.5972 19.5385H21.2027C22.5273 19.5385 23 19.1586 23 18.4161C23 16.2393 20.2745 13.2357 15.8959 13.2357C11.5255 13.2357 8.79996 16.2393 8.79996 18.4165C8.79996 19.1586 9.27229 19.5385 10.5972 19.5385Z" fill="#FD0578" />
                </svg>
            );
        case ICON_BAG:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.25022 7.013V6C8.25022 5.00544 8.64531 4.05161 9.34857 3.34835C10.0518 2.64509 11.0057 2.25 12.0002 2.25C12.9948 2.25 13.9486 2.64509 14.6519 3.34835C15.3551 4.05161 15.7502 5.00544 15.7502 6V7.013C17.0472 7.05 17.8372 7.183 18.4422 7.68C19.2722 8.36 19.5022 9.514 19.9652 11.823L20.5652 14.823C21.2292 18.143 21.5612 19.803 20.6612 20.902C19.7602 22 18.0672 22 14.6802 22H9.32022C5.93422 22 4.24022 22 3.34022 20.902C2.44022 19.804 2.77222 18.144 3.43622 14.823L4.03622 11.823C4.49822 9.514 4.72922 8.36 5.55822 7.68C6.16422 7.184 6.95422 7.05 8.25122 7.013M9.75022 6C9.75022 5.40326 9.98728 4.83097 10.4092 4.40901C10.8312 3.98705 11.4035 3.75 12.0002 3.75C12.597 3.75 13.1693 3.98705 13.5912 4.40901C14.0132 4.83097 14.2502 5.40326 14.2502 6V7H9.75022V6ZM15.0002 11C15.2654 11 15.5198 10.8946 15.7073 10.7071C15.8949 10.5196 16.0002 10.2652 16.0002 10C16.0002 9.73478 15.8949 9.48043 15.7073 9.29289C15.5198 9.10536 15.2654 9 15.0002 9C14.735 9 14.4807 9.10536 14.2931 9.29289C14.1056 9.48043 14.0002 9.73478 14.0002 10C14.0002 10.2652 14.1056 10.5196 14.2931 10.7071C14.4807 10.8946 14.735 11 15.0002 11ZM10.0002 10C10.0002 10.2652 9.89487 10.5196 9.70733 10.7071C9.51979 10.8946 9.26544 11 9.00022 11C8.73501 11 8.48065 10.8946 8.29312 10.7071C8.10558 10.5196 8.00022 10.2652 8.00022 10C8.00022 9.73478 8.10558 9.48043 8.29312 9.29289C8.48065 9.10536 8.73501 9 9.00022 9C9.26544 9 9.51979 9.10536 9.70733 9.29289C9.89487 9.48043 10.0002 9.73478 10.0002 10Z" fill="#FC0658" />
                </svg>
            );
        case ICON_COURSE:
            return (
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_35_1805)">
                        <path d="M12.833 12.5234L9 16.25L5.16696 12.5234C2.2942 12.6445 0 14.9297 0 17.75V18.125C0 19.1602 0.863839 20 1.92857 20H16.0714C17.1362 20 18 19.1602 18 18.125V17.75C18 14.9297 15.7058 12.6445 12.833 12.5234ZM0.546429 3.11719L0.803571 3.17578V5.45703C0.522321 5.62109 0.321429 5.90625 0.321429 6.25C0.321429 6.57812 0.50625 6.85156 0.767411 7.01953L0.140625 9.45312C0.0723214 9.72266 0.225 10 0.445982 10H2.12545C2.34643 10 2.49911 9.72266 2.4308 9.45312L1.80402 7.01953C2.06518 6.85156 2.25 6.57812 2.25 6.25C2.25 5.90625 2.04911 5.62109 1.76786 5.45703V3.40234L4.41964 4.02344C4.07411 4.69531 3.85714 5.44531 3.85714 6.25C3.85714 9.01172 6.15938 11.25 9 11.25C11.8406 11.25 14.1429 9.01172 14.1429 6.25C14.1429 5.44531 13.9299 4.69531 13.5804 4.02344L17.4496 3.11719C18.1808 2.94531 18.1808 2.05859 17.4496 1.88672L9.79955 0.0898437C9.27723 -0.03125 8.72679 -0.03125 8.20446 0.0898437L0.546429 1.88281C-0.180804 2.05469 -0.180804 2.94531 0.546429 3.11719Z" fill="#FE02BF" />
                    </g>
                    <defs>
                        <clipPath id="clip0_35_1805">
                            <rect width="18" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );

        default:
            return (
                <>
                </>
            );
    }
};


export default IconCollection;
