import { storableError } from '../../util/errors';
import { createSubscription, getAllListForMembership, getQuotes, getSubscription } from '../../util/api';
import { fetchCurrentUser } from '../../ducks/user.duck';
// ================ Action types ================ //
export const PLAN_LIST_REQUEST = 'app/SubscriptionPage/PLAN_LIST_REQUEST';
export const PLAN_LIST_SUCCESS = 'app/SubscriptionPage/PLAN_LIST_SUCCESS';
export const PURCHASE_LIST_SUCCESS = 'app/SubscriptionPage/PURCHASE_LIST_SUCCESS';
export const PURCHASE_LIST_REQUEST = 'app/SubscriptionPage/PURCHASE_LIST_REQUEST';
export const PLAN_LIST_ERROR = 'app/SubscriptionPage/PLAN_LIST_ERROR';
export const SUBSCRIPTION_ERROR = 'app/SubscriptionPage/SUBSCRIPTION_ERROR';
export const QUOTE_LIST_REQUEST = 'app/SubscriptionPage/QUOTE_LIST_REQUEST';
export const QUOTE_LIST_SUCCESS = 'app/SubscriptionPage/QUOTE_LIST_SUCCESS';
export const QUOTE_LIST_ERROR = 'app/SubscriptionPage/QUOTE_LIST_ERROR';

// ================ Reducer ================ //
const initialState = {
    planListError: null,
    planListInProgress: true,
    purchaselistInProgress:true,
    createsubscriptionInProgress: true,
    planList: [],
    purchaseList: [],
    quoteList:[],
    quoteListInProgress: false,
    quoteListError: null
};
export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case PLAN_LIST_REQUEST:
            return {
                ...state,
                planList: null,
                planListError: null,
                planListInProgress: true,
            };
        case PLAN_LIST_SUCCESS:
            return {
                ...state,
                planListInProgress: false,
                planList: payload
            };
            case PURCHASE_LIST_REQUEST:
            return {
                ...state,
                purchaseList: null,
                createsubscriptionError: null,
                purchaselistInProgress: true,
            };
        case PURCHASE_LIST_SUCCESS:
            return {
                ...state,
                purchaselistInProgress: false,
                purchaseList: payload
            };

        case PLAN_LIST_ERROR:
            return {
                ...state,
                planListInProgress: false,
                planListError: payload
            };
        case SUBSCRIPTION_ERROR:
            return {
                ...state,
                createsubscriptionInProgress: false,
                createsubscriptionError: payload
            };
            

            case QUOTE_LIST_REQUEST:
            return {
                ...state,
                quoteList: null,
                quoteListError: null,
                quoteListInProgress: true,
            };
        case QUOTE_LIST_SUCCESS:
            return {
                ...state,
                quoteListInProgress: false,
                quoteList: payload
            };
            case QUOTE_LIST_ERROR:
            return {
                ...state,
                createsubscriptionError: null,
                quoteListInProgress: false,
                quoteListError: payload
            };

        default:
            return state;
    }
}
// ================ Action creators ================ //
export const planListRequest = () => ({ type: PLAN_LIST_REQUEST });
export const planListSuccess = (res) => ({ type: PLAN_LIST_SUCCESS, payload: res });
export const planListError = () => ({ type: PLAN_LIST_ERROR });
export const purchaselistRequest = () => ({ type: PURCHASE_LIST_REQUEST });
export const purchaselistSuccess = (data) => ({ type: PURCHASE_LIST_SUCCESS, payload: data });
export const createsubscriptionsError = (showmessage) => ({ type: SUBSCRIPTION_ERROR, payload: showmessage });
export const quoteDataRequest = () => ({ type: QUOTE_LIST_REQUEST });
export const quoteDataSuccess = (res) => ({ type: QUOTE_LIST_SUCCESS, payload: res });
export const quoteDataError = () => ({ type: QUOTE_LIST_ERROR });

export const subscriptionThroughChargeBee = ({ customerId, courseId, cardValues }) => (dispatch, getState, sdk) => {

    return createSubscription({ customerId, courseId, cardValues })
        .then()
        
        .catch(error => {
            let errorMessage = 'Error creating subscription';
            if (error.message && error.message.includes("Your card's expiration year is invalid")) {
                errorMessage = "Your card's expiration year is invalid. Please update your card details.";
            } else if (error.message) {
                errorMessage = error.message;
            }
            const storableError = {
                message: errorMessage,
                status: error.status || 'Unknown status',
            };

            const showmessage = storableError.message
            dispatch(createsubscriptionsError(showmessage));
        });
};

// ================ Thunks ================ //


export const getQuotesFromApi = (params) => async (dispatch, getState, sdk) => {
    dispatch(quoteDataRequest());
    await getQuotes(params).then((res) => {
        dispatch(quoteDataSuccess(res));
        return res;
    });
};

export const loadData = () => async (dispatch, getState, sdk) => {
    dispatch(planListRequest());
    try {
        const res = await getAllListForMembership('list');
        const planList = res;
        const filteredData = planList && planList.filter(item => item.item_price.status === 'active');
        dispatch(planListSuccess(res));
        let { currentUser } = getState().user;
        if (!currentUser || (currentUser && !currentUser.id)) {
            currentUser = await dispatch(fetchCurrentUser());
        }
        const { uuid: customerId } = (currentUser && currentUser.id) || {};
        const data = await getSubscription({ customerId });
        const showPurchaseList = data && data.list;
        const subscriptionsData = showPurchaseList && showPurchaseList.map(item => ({
            endtime: item.subscription.current_term_end,
            status: item.subscription?.status,
            itemPriceId: item.subscription?.subscription_items[0]?.item_price_id
        }));
        const filteredByshowSubscription = subscriptionsData && subscriptionsData.length > 0 ?
            filteredData && filteredData.filter(item =>
                !subscriptionsData.some(subItem => subItem.itemPriceId === item.item_price.id)
            ) : filteredData;
        const filteredBySubscription = checkButtonStatus(subscriptionsData, filteredData, filteredByshowSubscription);
        dispatch(purchaselistSuccess({ data: filteredBySubscription }));
        return data;
    } catch (e) {
        dispatch(planListError(storableError(e)));
        throw e;
    }
};

function checkButtonStatus(subscriptionsData, filteredData, filteredByShowSubscription) {
    // Ensure subscriptionsData is not undefined or null before proceeding
    if (!subscriptionsData) return filteredData;

    // Filter out different subscription statuses
    const activeSubscriptions = subscriptionsData.filter(sub => sub.status === "active");
    const trialSubscriptions = subscriptionsData.filter(sub => sub.status === "in_trial");
    const cancelledSubscriptions = subscriptionsData.filter(sub => sub.status === "non_renewing" || sub.status === "cancelled");
    const pausedSubscriptions = subscriptionsData.filter(sub => sub.status === "paused");

    // Check if any active or trial subscriptions exist
    if (activeSubscriptions.length > 0 || trialSubscriptions.length > 0) {
        return filteredByShowSubscription;
    }

    // Check for cancelled subscriptions that have an endtime in the future or today
    const currentDate = new Date();
    const hasFutureOrTodayEndDate = cancelledSubscriptions.some(sub => {
        const endDate = new Date(sub.endtime);
        return endDate >= currentDate; // Should be >= to include today
    });

    if (hasFutureOrTodayEndDate) {
        const filteredCancelledSubscriptions = cancelledSubscriptions.filter(sub => {
            const endDate = new Date(sub.endtime);
            return endDate >= currentDate;
        });

        const filteredItemPriceIds = filteredCancelledSubscriptions.map(sub => sub.itemPriceId);
        const filteredDataWithEndDate = filteredData.filter(item => filteredItemPriceIds.includes(item.item_price.id));

        return filteredDataWithEndDate; // Return the correct filtered data
    } else if (pausedSubscriptions.length > 0) {
        return []; // Return no data if subscriptions are paused
    }

    return filteredData; // Default return if no conditions match
}


