import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API


// {
//   "_sdkType": "LatLngBounds",
//   "ne": {
//       "_sdkType": "LatLng",
//       "lat": 29.749,
//       "lng": -98.253657
//   },
//   "sw": {
//       "_sdkType": "LatLng",
//       "lat": 29.100796,
//       "lng": -98.88641
//   }
// }



const defaultLocations = [
  {
    id: 'default-austin',
    predictionPlace: {
      address: 'Austin, Texas',
      bounds: new LatLngBounds(new LatLng(30.519669, -97.541669), new LatLng(30.067944, -98.076759)),
    },
  },
  {
    id: 'default-san-antonio',
    predictionPlace: {
      address: 'San Antonio, Texas',
      bounds: new LatLngBounds(new LatLng(29.749, -98.253657), new LatLng(29.100796, -98.88641)),
    },
  },
  {
    id: 'default-houston',
    predictionPlace: {
      address: 'Houston, Texas',
      bounds: new LatLngBounds(new LatLng(30.085555, -95.054091), new LatLng(29.5231884,-95.730396)),
    },
  },
  {
    id: 'default-dallas',
    predictionPlace: {
      address: 'Dallas, Texas',
      bounds: new LatLngBounds(new LatLng(33.016499, -96.555516), new LatLng(32.618564, -96.995725)),
    },
  }
  
];
export default defaultLocations;
