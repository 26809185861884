import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { copyToClipboard } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink, IconShare, Modal, OutsideClickHandler } from "../../components";

import css from './SocialShare.module.css';

const SocialShare = props => {
  const {
    url,
    className,
    isOpen,
    onClose,
    PageName,
    onManageDisableScrolling
  } = props;


  const [copyText, setCopyText] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setCopyText(false);
    }, 5000);
  }, [copyText]);

  const displayTitle = 'Check out this Provider'

  return (
    <>
      <Modal
        id="ListingPage.SocialShare"
        isOpen={isOpen}
        onClose={onClose}
        onManageDisableScrolling={onManageDisableScrolling}
        className={css.shareModal}
      >
        <OutsideClickHandler onOutsideClick={() => isOpen && onClose()} className={css.dblock}>
          <div className={css.enquirySuccessModal}>
            <h4 className={css.modalTitle}>Share This Service</h4>
            <div className={css.shareWith}>
              <ul className={css.socialMedialinksBox}>
              <li
                  onClick={() => [copyToClipboard(url), setCopyText(true), onClose()]}
                >
                  <div className={css.copyButton}>
                    <IconShare type='CopyLockIcon' />
                    {/* <span>Copy link </span> */}
                  </div>
                </li>
                {/* <li>
                  <FacebookShareButton
                    url={url}
                    quote={displayTitle}
                    className={css.facebookButton}
                  >
                    <IconShare type='facebook' />
                   
                  </FacebookShareButton>
                </li> */}

                {/* <li>
                  <LinkedinShareButton
                    title={displayTitle}
                    url={url}
                    className={css.linkedinButton}
                  >
                    <IconShare type='linkedin' />
             
                  </LinkedinShareButton>
                </li> */}

             
              </ul>
            </div>
          </div>
        </OutsideClickHandler>
      </Modal>

      <div className={classNames(css.textCopiedPopup, copyText ? css.popUpEntrada : css.popUpSaids)} onClick={() => setCopyText(false)} >
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" >
          <ellipse cx="10" cy="11" rx="10" ry="10.5" fill="#A9E7B7" />
          <path d="M15.1583 7.63492L8.01225 14.3049L5.85325 12.1469C5.65825 11.9519 5.34125 11.9519 5.14625 12.1469C4.95125 12.3419 4.95125 12.6589 5.14625 12.8539L7.64625 15.3539C7.74325 15.4509 7.87125 15.4999 7.99925 15.4999C8.12225 15.4999 8.24425 15.4559 8.34025 15.3659L15.8403 8.36592C16.0423 8.17792 16.0523 7.86092 15.8643 7.65892C15.6763 7.45692 15.3613 7.44592 15.1583 7.63492Z" stroke="#00A524" />
        </svg>
        <span className={css.close}>
          <IconShare type='copy' />
        </span>
      </div>
    </>
  );
};

export default SocialShare;
